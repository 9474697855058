import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { AppConfig } from "../../utils/app-config";
import { useAppDispatch } from "../../app/hooks";
import { userActivateAsync } from "../../features/authentication/auth-api";
import MainLayout from "../../components/main-layout";
import { RouteParams } from "../../types";

function UserActivate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<RouteParams>();

  const activateAccount = async () => {
    const uid = params.uid as string;
    const token = params.token as string;
    const activationData = {
      uid,
      token,
    };
    const resultAction = await dispatch(userActivateAsync(activationData));
    if (userActivateAsync.fulfilled.match(resultAction)) {
      navigate("/login");
    }
  };
  return (
    <MainLayout>
      <div className="bg-white dark:bg-gray-900">
        <div className="flex justify-center h-screen">
          <div
            className="hidden bg-cover lg:block lg:w-2/3"
            style={{
              backgroundImage: "url(/static/images/undraw_launch_day_4e04.png)",
            }}
          >
            <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
              <div>
                <h2 className="text-4xl font-bold text-white">
                  {AppConfig.siteName}
                </h2>
                <p className="max-w-xl mt-3 text-gray-300">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. In
                  autem ipsa, nulla laboriosam dolores, repellendus perferendis
                  libero suscipit nam temporibus molestiae
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
            <div className="flex-1">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">
                  {AppConfig.siteName}
                </h2>
              </div>
              <button
                onClick={activateAccount}
                type="button"
                className="w-full inline-flex items-center mt-6 justify-center px-4 py-2 bg-blue-600 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-blue-700 active:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 disabled:opacity-25 transition"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default UserActivate;
