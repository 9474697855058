import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Link, useLocation } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import { useAppSelector } from "../app/hooks";
import {
  selectIsAuthenticated,
  selectAccess,
} from "../features/authentication/auth-slice";
import { Plan } from "../types";
import MainLayout from "../components/main-layout";
import Spinner from "../components/ui/spinner/spinner";
import { getApiURL } from "../utils/helpers";

function Pricing() {
  const stripe = useStripe();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const token = useAppSelector(selectAccess);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [err, setErr] = useState(false);
  const [showYearlyPricing, setShowYearlyPricing] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();
    const getProductDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${getApiURL()}/api/subscriptions/subscribable-product/`,
          {
            method: "GET",
            signal: abortController.signal,
          },
        );
        const data = await response.json();
        const fetchedPlans = data;
        setPlans(fetchedPlans);
      } catch (e) {
        setErr(true);
        // if (!abortController.signal.aborted) {
        //   console.log('abort related');
        // }
      }
      setLoading(false);
    };

    getProductDetails();

    return () => {
      abortController.abort();
      setLoading(false);
    };
  }, []);

  const checkoutHandler = async (prc: string) => {
    const priceID = {
      price_id: prc,
    };

    const response = await fetch(`${getApiURL()}/api/subscriptions/checkout/`, {
      method: "POST",
      body: JSON.stringify(priceID),
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
        Accept: "application/json",
      },
    });

    const data = await response.json();

    // Redirect to Checkout.
    const { error } = await stripe!.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: data?.session_id,
    });
    if (error) {
      setErr(true);
    }
  };

  return (
    <MainLayout>
      <section>
        <div className="container px-6 py-16 mx-auto text-center">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white md:text-4xl">
              Pricing Plan
            </h1>
            <p className="mt-6 text-gray-500 dark:text-gray-300">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero
              similique obcaecati illum mollitia.
            </p>
            <button
              type="submit"
              className="px-6 py-2 mt-6 text-sm font-medium leading-5 text-center text-white capitalize bg-blue-600 rounded-lg hover:bg-blue-500 md:mx-0 md:w-auto focus:outline-none"
            >
              Contact us
            </button>
          </div>
        </div>

        {loading && <Spinner />}

        {!loading && plans && (
          <div className="container px-6 py-16 mx-auto text-center">
            {showYearlyPricing && (
              <button
                onClick={() => setShowYearlyPricing(false)}
                type="submit"
                className="px-6 py-2 mt-6 text-sm font-medium leading-5 text-center text-white capitalize bg-blue-600 rounded-lg hover:bg-blue-500 md:mx-0 md:w-auto focus:outline-none"
              >
                Show monthly plans
              </button>
            )}

            {!showYearlyPricing && (
              <button
                onClick={() => setShowYearlyPricing(true)}
                type="submit"
                className="px-6 py-2 mt-6 text-sm font-medium leading-5 text-center text-white capitalize bg-blue-600 rounded-lg hover:bg-blue-500 md:mx-0 md:w-auto focus:outline-none"
              >
                Show yearly plans
              </button>
            )}
            <div className="container px-6 py-8 mx-auto">
              <div className="flex flex-col items-center justify-center space-y-8 lg:-mx-4 lg:flex-row lg:items-stretch lg:space-y-0">
                {plans
                  .filter((item) =>
                    showYearlyPricing
                      ? item.freq === "year_1"
                      : item.freq === "month_1",
                  )
                  .map((plan: Plan) => (
                    <div
                      key={uuidv4() as string}
                      className="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-gray-200 rounded-lg lg:mx-4 dark:bg-gray-800 dark:border-gray-700"
                    >
                      <div className="flex-shrink-0">
                        <h2 className="inline-flex items-center justify-center px-2 font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700">
                          {plan.name}
                        </h2>
                        <h2 className="inline-flex items-center justify-center px-2 font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700">
                          {plan.currency} {plan.price / 100}
                        </h2>
                      </div>

                      {!isAuthenticated && (
                        <Link to="/login" state={{ from: location }} replace>
                          <button
                            type="submit"
                            className="inline-flex items-center justify-center px-4 py-2 font-medium text-white uppercase transition-colors bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none"
                          >
                            Login to Checkout
                          </button>
                        </Link>
                      )}

                      {isAuthenticated && (
                        <button
                          type="button"
                          onClick={() => checkoutHandler(plan.price_id)}
                          id={plan.price_id as string}
                          className="inline-flex items-center justify-center px-4 py-2 font-medium text-white uppercase transition-colors bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                          Go to Checkout
                        </button>
                      )}
                    </div>
                  ))}
              </div>

              {err && <p>An error occured. Please try again.</p>}
            </div>
          </div>
        )}
      </section>
    </MainLayout>
  );
}

export default React.memo(Pricing);
