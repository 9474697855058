import { createAsyncThunk } from "@reduxjs/toolkit";

import jwtDecode, { JwtPayload } from "jwt-decode";

import { RootState, AppDispatch } from "../../app/store";
import {
  SignUpFormData,
  LoginFormData,
  TokenResponse,
  UserDetails,
  RouteParams,
  ConfirmUsernameResetFormData,
  ConfirmPasswordResetFormData,
} from "../../types";
import { getApiURL } from "../../utils/helpers";

export interface AuthApiError {
  errorMessage: any;
}

export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("refresh");
  const state = {} as RootState;
  return state;
});

export const signupAsync = createAsyncThunk<
  UserDetails,
  SignUpFormData,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/signup", async (formData, thunkApi) => {
  const { name, username, email, password, re_password } = formData;
  const body = JSON.stringify({
    name,
    username,
    email,
    password,
    re_password,
  });
  const response = await fetch(`${getApiURL()}/auth/users/`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status === 400) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue({ errorMessage: data } as AuthApiError);
  }
  return data;
});

// The token is passed as an argument
export const loadUserAsync = createAsyncThunk<
  UserDetails,
  string,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/loaduser", async (token, thunkAPI) => {
  const response = await fetch(`${getApiURL()}/auth/users/me/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
      Accept: "application/json",
    },
  });
  const data = await response.json();
  if (response.status === 400) {
    return thunkAPI.rejectWithValue({ errorMessage: data });
  }
  return data;
});

export const loginAsync = createAsyncThunk<
  TokenResponse,
  LoginFormData,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/login", async (formData, thunkApi) => {
  const { email, password } = formData;
  const body = JSON.stringify({
    email,
    password,
  });
  const response = await fetch(`${getApiURL()}/auth/jwt/create/`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status === 400 || response.status === 401) {
    return thunkApi.rejectWithValue({ errorMessage: data } as AuthApiError);
  }
  localStorage.setItem("refresh", data.refresh);
  return data;
});

export const userActivateAsync = createAsyncThunk<
  void,
  RouteParams,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/activateuser", async (verificationData, thunkApi) => {
  const { uid, token } = verificationData;
  const body = JSON.stringify({ uid, token });
  const response = await fetch(`${getApiURL()}/auth/users/activation/`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 400 || response.status === 403) {
    const data = await response.json();
    return thunkApi.rejectWithValue({ errorMessage: data } as AuthApiError);
  }
});

export const userReActivateAsync = createAsyncThunk<
  void,
  string,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/resendactivation", async (email, thunkApi) => {
  const body = JSON.stringify({ email });
  const response = await fetch(`${getApiURL()}/auth/users/resend_activation/`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 400) {
    return thunkApi.rejectWithValue({
      errorMessage: { message: "Error for sending the email" },
    } as AuthApiError);
  }
});

export const userDeleteAsync = createAsyncThunk<
  void,
  string,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/deleteuser", async (currentPassword, thunkApi) => {
  const { auth } = thunkApi.getState();
  const current_password = currentPassword;
  const body = JSON.stringify({ current_password });
  const response = await fetch(`${getApiURL()}/auth/users/me/`, {
    method: "DELETE",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${auth.access}`,
    },
  });
  if (response.status === 400) {
    const data = await response.json();
    return thunkApi.rejectWithValue({ errorMessage: data } as AuthApiError);
  }
});

export const resetUserNameAsync = createAsyncThunk<
  void,
  string,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/resetusername", async (email, thunkApi) => {
  const body = JSON.stringify({ email });
  const response = await fetch(`${getApiURL()}/auth/users/reset_email/`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.status === 400) {
    return thunkApi.rejectWithValue({
      errorMessage: { message: "Error for sending the email" },
    } as AuthApiError);
  }
});

export const confirmUsernameReset = createAsyncThunk<
  null,
  ConfirmUsernameResetFormData,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/confirmusernamereset", async (confirmationData, thunkApi) => {
  const { uid, token, new_username } = confirmationData;
  const body = JSON.stringify({ uid, token, new_username });
  const response = await fetch(
    `${getApiURL()}/auth/users/reset_username_confirm/`,
    {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  if (response.status === 400) {
    const data = await response.json();
    return thunkApi.rejectWithValue({ errorMessage: data } as AuthApiError);
  }
  return null;
});

export const resetPasswordAsync = createAsyncThunk<
  void,
  string,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/resetpassword", async (email, thunkApi) => {
  const body = JSON.stringify({ email });
  const response = await fetch(`${getApiURL()}/auth/users/reset_password/`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 400) {
    const data = await response.json();
    return thunkApi.rejectWithValue({ errorMessage: data } as AuthApiError);
  }
});

export const resetPasswordConfirmAsync = createAsyncThunk<
  void,
  ConfirmPasswordResetFormData,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/resetpasswordconfirm", async (confirmationData, thunkApi) => {
  const { uid, token, new_password, re_new_password } = confirmationData;
  const body = JSON.stringify({
    uid,
    token,
    new_password,
    re_new_password,
  });
  const response = await fetch(
    `${getApiURL()}/auth/users/reset_password_confirm/`,
    {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  if (response.status === 400) {
    const data = await response.json();
    return thunkApi.rejectWithValue({ errorMessage: data } as AuthApiError);
  }
});

export const refreshAccessTokenAsync = createAsyncThunk<
  string,
  string,
  { rejectWithValue: AuthApiError; dispatch: AppDispatch; state: RootState }
>("auth/refreshaccess", async (refresh, thunkApi) => {
  const body = JSON.stringify({ refresh });
  const response = await fetch(`${getApiURL()}/auth/jwt/refresh`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status === 401) {
    localStorage.removeItem("refresh");
    return thunkApi.rejectWithValue({ errorMessage: data } as AuthApiError);
  }
  return data.access;
});

export const isTokenExpired = (token: string) => {
  try {
    const decoded = jwtDecode<JwtPayload>(token);
    const exp = decoded.exp as number;
    if (exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("refresh");
  return storedToken;
};
