/* eslint-disable  react/require-default-props */
import React, { ReactNode, useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { useAuth } from "../hooks/use-auth";

import {
  isTokenExpired,
  loadUserAsync,
  refreshAccessTokenAsync,
  retrieveStoredToken,
} from "../features/authentication/auth-api";

import MainNavbar from "./ui/main-navbar";

export interface IMainLayoutProps {
  children?: ReactNode;
}

function MainLayout({ children }: IMainLayoutProps) {
  const { accessToken } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (accessToken && !isTokenExpired(accessToken)) {
      dispatch(loadUserAsync(accessToken));
    } else {
      const refreshToken = retrieveStoredToken();
      if (refreshToken) {
        dispatch(refreshAccessTokenAsync(refreshToken));
      }
    }
  }, [accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MainNavbar />
      <main className="mx-auto container px-6 py-6" id="skip">
        {children}
      </main>
    </>
  );
}

export default MainLayout;
