import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppDispatch } from "../../app/hooks";
import { signupAsync } from "../../features/authentication/auth-api";
import { useAuth } from "../../hooks/use-auth";
import Error from "../error/error";
import { SignUpFormData } from "../../types";
import Spinner from "../ui/spinner/spinner";

function SignupForm() {
  const { error } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const defaultValues = {};
  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm, isSubmitting, isSubmitted, isValid },
    setError: setErrorForm,
  } = useForm<SignUpFormData>({ defaultValues });

  const handleSignup = handleSubmitForm(async (formData: SignUpFormData) => {
    const data = await dispatch(
      signupAsync({
        username: formData.username,
        name: formData.name,
        password: formData.password,
        re_password: formData.re_password,
        email: formData.email,
      }),
    );
    if (signupAsync.fulfilled.match(data)) {
      // setUserCreated(true);
      navigate("/login");
    } else {
      setErrorForm("username", {
        message: "Error happened while registering",
      });
    }
  });

  return (
    <div className="mt-8">
      <div className="mt-6">
        {isSubmitted && !isSubmitting && !isValid && (
          <div className="mt-6 text-gray-600 dark:text-gray-200">
            {errorsForm.username && errorsForm.username.message}
            <Error error={error} />
          </div>
        )}
      </div>
      <form onSubmit={handleSignup}>
        <div className="mt-6">
          <label
            htmlFor="email"
            className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
          >
            Email Address
            <input
              type="email"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("email", {
                required: true,
              })}
            />
          </label>
        </div>

        <div className="mt-6">
          <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
            Username
            <input
              type="input"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("username", {
                required: true,
              })}
            />
          </label>
          {errorsForm.username?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">
              User name is required
            </p>
          )}
        </div>

        <div className="mt-6">
          <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
            Name
            <input
              type="input"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("name", {
                required: true,
              })}
            />
          </label>
          {errorsForm.username?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">
              User name is required
            </p>
          )}
        </div>

        <div className="mt-6">
          <label
            htmlFor="password"
            className="text-sm text-gray-600 dark:text-gray-200"
          >
            Password
            <input
              type="password"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("password", {
                required: true,
              })}
            />
          </label>
          {errorsForm.password?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">
              Password is required
            </p>
          )}
        </div>

        <div className="mt-6">
          <label
            htmlFor="password"
            className="text-sm text-gray-600 dark:text-gray-200"
          >
            Re-password
            <input
              type="password"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("re_password", {
                required: true,
              })}
            />
          </label>
          {errorsForm.password?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">
              Password is required
            </p>
          )}
        </div>

        <div className="mt-6">
          {isSubmitting && (
            <div>
              <Spinner />
            </div>
          )}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
          >
            Register
          </button>
        </div>
      </form>

      <p className="mt-6 text-sm text-center text-gray-400">
        You have an account?
        <Link
          to="/login"
          className="text-blue-500 focus:outline-none focus:underline hover:underline"
        >
          Login
        </Link>
        .
      </p>
    </div>
  );
}

export default SignupForm;
