import React from "react";

import MainLayout from "../components/main-layout";

function ProtectedPage() {
  return (
    <MainLayout>
      <section>
        <div className="container px-6 py-16 mx-auto text-center">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white md:text-4xl">
              Protected page
            </h1>
            <p className="mt-6 text-gray-500 dark:text-gray-300">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero
              similique obcaecati illum mollitia.
            </p>
            <button
              type="submit"
              className="px-6 py-2 mt-6 text-sm font-medium leading-5 text-center text-white capitalize bg-blue-600 rounded-lg hover:bg-blue-500 md:mx-0 md:w-auto focus:outline-none"
            >
              Contact us
            </button>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export default React.memo(ProtectedPage);
