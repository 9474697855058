import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppDispatch } from "../../app/hooks";
import { resetUserNameAsync } from "../../features/authentication/auth-api";
import { useAuth } from "../../hooks/use-auth";
import { EmailInputFormData } from "../../types";
import Error from "../error/error";
import Spinner from "../ui/spinner/spinner";

function ResetUsernameForm() {
  const { error } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const defaultValues = {};
  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm, isSubmitting, isSubmitted, isValid },
    setError: setErrorForm,
  } = useForm<EmailInputFormData>({ defaultValues });

  const handleUserNameReset = handleSubmitForm(
    async (formData: EmailInputFormData) => {
      const data = await dispatch(resetUserNameAsync(formData.email));

      if (resetUserNameAsync.fulfilled.match(data)) {
        navigate("/");
      } else {
        setErrorForm("email", { message: "Error happened!" });
      }
    },
  );

  return (
    <div className="mt-8">
      <div className="mt-6">
        {isSubmitted && !isSubmitting && !isValid && (
          <div className="mt-6 text-gray-600 dark:text-gray-200">
            {errorsForm.email && errorsForm.email.message}
            <Error error={error} />
          </div>
        )}
      </div>

      <form onSubmit={handleUserNameReset}>
        <div className="mt-6">
          <label
            htmlFor="email"
            className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
          >
            Email Address
            <input
              type="email"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("email", {
                required: true,
              })}
            />
          </label>
          {errorsForm.email?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">Required</p>
          )}
        </div>

        <div className="mt-6">
          {isSubmitting && (
            <div>
              <Spinner />
            </div>
          )}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
          >
            Reset user name
          </button>
        </div>
      </form>
    </div>
  );
}

export default ResetUsernameForm;
