import React from "react";
import { RefreshIcon } from "@heroicons/react/outline";

function Spinner() {
  return (
    <div className="flex items-center justify-center w-full h-full flex-grow gap-2">
      <RefreshIcon className="animate-spin w-5 h-5" />
    </div>
  );
}

export default Spinner;
