import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../hooks/use-auth";

function UserAccountDetails() {
  const { user } = useAuth();

  return (
    <div className="flex-1 relative pb-8 z-0 overflow-y-auto">
      <div className="hidden md:block bg-white shadow lg:border-t lg:border-gray-200">
        <div className="px-4 sm:px-8  mx-auto">
          <div className="flex-1 min-w-0">
            <h1 className="">Account Details</h1>
          </div>
        </div>
      </div>

      <div className="px-4 sm:px-8 mx-auto py-5 grid gap-5">
        <div className="mt-2 grid sm:grid-cols-1 gap-5">
          <div className="space-y-5">
            <div className="flex items-center space-x-2 justify-between">
              <div className="max-w-xs mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800">
                <img
                  className="object-cover w-full h-56"
                  src="https://images.unsplash.com/photo-1542156822-6924d1a71ace?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                  alt="avatar"
                />
                <div className="py-5 text-center">
                  <p className="block text-2xl font-bold text-gray-800 dark:text-white">
                    {user?.name}
                  </p>

                  <p className="text-sm text-gray-700 dark:text-gray-200">
                    {user?.username}
                  </p>

                  <p className="text-sm text-gray-700 dark:text-gray-200">
                    {user?.email}
                  </p>
                  <Link
                    className="px-6 py-6 text-gray-700  rounded-md dark:bg-gray-700 dark:text-gray-200"
                    to="/app/account/delete"
                  >
                    <button
                      type="submit"
                      className="px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                    >
                      Delete Account
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAccountDetails;
