import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import RequireAuth from "./components/authentication/require-auth";
import About from "./pages/About";
import Home from "./pages/Home";
import Faq from "./pages/Faq";
import Dashboard from "./pages/dashboard/Dashboard";
import Pricing from "./pages/Pricing";
import ProtectedPage from "./pages/ProtectedPage";
import ResetPassword from "./pages/authentication/ResetPassword";
import ConfirmPasswordReset from "./pages/authentication/ConfirmPasswordReset";
import ResetUserName from "./pages/authentication/ResetUserName";
import UserDelete from "./pages/account/UserDelete";
import ConfirmUsernameReset from "./pages/authentication/ConfirmUsernameReset";
import UserActivate from "./pages/authentication/UserActivate";
import ResendActivation from "./pages/authentication/ResendActivation";
import Success from "./pages/subscription/SubscriptionSuccess";
import Cancel from "./pages/subscription/SubscriptionCancel";
import UserAccountDetails from "./pages/account/UserAccountDetails";
import UserSubscriptionDetails from "./pages/account/UserSubscriptionDetails";
import SignUp from "./pages/authentication/Signup";
import SignIn from "./pages/authentication/Signin";
import { DashboardLayout } from "./components/dashboard";

function App() {
  return (
    <div className="root">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/pricing" element={<Pricing />} />

        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<SignIn />} />

        {/* This link for this page is supposed to send via email for the user to activate the account  */}
        {/* In the backend settings set ACTIVATION_URL  */}
        {/* The activation process is based on SEND_ACTIVATION_EMAIL in settings  */}
        <Route path="/activate/:uid/:token" element={<UserActivate />} />

        {/* Use this endpoint to re-send the activation e-mail to the customer. */}
        {/* The activation process is based on SEND_ACTIVATION_EMAIL in settings  */}
        <Route path="/activation-resend" element={<ResendActivation />} />

        {/* To send email to user with username reset link. */}
        {/* Set up USERNAME_RESET_CONFIRM_URL in the settings. This is for the path in the front end  */}
        <Route path="/reset-email" element={<ResetUserName />} />
        <Route
          path="/username/reset/confirm/:uid/:token"
          element={<ConfirmUsernameReset />}
        />

        {/* To send email to user with password reset link */}
        {/* PASSWORD_RESET_CONFIRM_URL needs to be set up for the front end  */}
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/password/reset/confirm/:uid/:token"
          element={<ConfirmPasswordReset />}
        />

        {/* Deletes the user totally.  */}
        {/* override User.delete to customize the delete behavior */}
        {/* <Route
          path="/delete-user"
          element={
            <RequireAuth>
              <UserDelete />
            </RequireAuth>
          }
        /> */}

        {/* Example page, user should be logged in to access the page  */}
        <Route
          path="/protected"
          element={
            <RequireAuth>
              <ProtectedPage />
            </RequireAuth>
          }
        />
        <Route path="/app" element={<Navigate replace to="/app/dashboard" />} />

        <Route
          path="/app"
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="account"
            element={
              <RequireAuth>
                <UserAccountDetails />
              </RequireAuth>
            }
          />
          <Route
            path="account/delete"
            element={
              <RequireAuth>
                <UserDelete />
              </RequireAuth>
            }
          />
          <Route
            path="subscription"
            element={
              <RequireAuth>
                <UserSubscriptionDetails />
              </RequireAuth>
            }
          />
        </Route>

        <Route path="/subscriptions/success" element={<Success />} />
        <Route path="/subscriptions/cancel" element={<Cancel />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
