import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppDispatch } from "../../app/hooks";
import { useAuth } from "../../hooks/use-auth";
import { resetPasswordConfirmAsync } from "../../features/authentication/auth-api";
import Error from "../error/error";
import { ConfirmPasswordResetFormData } from "../../types";
import Spinner from "../ui/spinner/spinner";

function ConfirmResetPasswordForm() {
  const { error } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const defaultValues = {};
  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm, isSubmitting, isSubmitted, isValid },
    setError: setErrorForm,
  } = useForm<ConfirmPasswordResetFormData>({ defaultValues });

  const handleConfirmPasswordReset = handleSubmitForm(
    async (formData: ConfirmPasswordResetFormData) => {
      const data = await dispatch(
        resetPasswordConfirmAsync({
          uid: params.uid,
          token: params.token,
          new_password: formData.new_password,
          re_new_password: formData.re_new_password,
        }),
      );
      if (resetPasswordConfirmAsync.fulfilled.match(data)) {
        navigate("/login");
      } else {
        setErrorForm("new_password", {
          message: "Password was not confirmed!",
        });
      }
    },
  );

  return (
    <div className="mt-8">
      <div className="mt-6">
        {isSubmitted && !isSubmitting && !isValid && (
          <div className="mt-6 text-gray-600 dark:text-gray-200">
            {errorsForm.new_password && errorsForm.new_password.message}
            <Error error={error} />
          </div>
        )}
      </div>

      <form onSubmit={handleConfirmPasswordReset}>
        <div className="mt-6">
          <label
            htmlFor="password"
            className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
          >
            New Password
            <input
              type="password"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("new_password", {
                required: true,
              })}
            />
          </label>
          {errorsForm.new_password?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">Required</p>
          )}
        </div>

        <div className="mt-6">
          <label
            htmlFor="password"
            className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
          >
            Re-new Password
            <input
              type="password"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("re_new_password", {
                required: true,
              })}
            />
          </label>
          {errorsForm.re_new_password?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">Required</p>
          )}
        </div>

        <div className="mt-6">
          {isSubmitting && (
            <div>
              <Spinner />
            </div>
          )}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
          >
            Reset password
          </button>
        </div>
      </form>
    </div>
  );
}

export default ConfirmResetPasswordForm;
