import React from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppDispatch } from "../../app/hooks";
import { loginAsync } from "../../features/authentication/auth-api";
import { useAuth } from "../../hooks/use-auth";
import Error from "../error/error";
import { LoginFormData } from "../../types";
import Spinner from "../ui/spinner/spinner";

export interface LocationState {
  from: {
    pathname: string;
  };
}

function JWTLoginForm() {
  const { error } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const from = locationState?.from?.pathname || "/";
  const defaultValues = {};
  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm, isSubmitting, isSubmitted, isValid },
    setError: setErrorForm,
  } = useForm<LoginFormData>({ defaultValues });

  const handleLogin = handleSubmitForm(async (formData: LoginFormData) => {
    const data = await dispatch(
      loginAsync({
        email: formData.email,
        password: formData.password,
      }),
    );

    if (loginAsync.fulfilled.match(data)) {
      navigate(from);
    } else {
      setErrorForm("email", {
        message: "Error happened while sigining in!",
      });
    }
  });

  return (
    <div className="mt-8">
      <div className="mt-6">
        {isSubmitted && !isSubmitting && !isValid && (
          <div className="mt-6 text-gray-600 dark:text-gray-200">
            {errorsForm.email && errorsForm.email.message}
            <Error error={error} />
          </div>
        )}
      </div>

      <form onSubmit={handleLogin}>
        <div className="mt-6">
          <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
            Email
            <input
              type="email"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("email", {
                required: true,
              })}
            />
          </label>
          <Link
            to="/reset-email"
            className="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"
          >
            Forgot username?
          </Link>
          {errorsForm.email?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">Required</p>
          )}
        </div>

        <div className="mt-6">
          <label
            htmlFor="password"
            className="text-sm text-gray-600 dark:text-gray-200"
          >
            Password
            <input
              type="password"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              {...registerForm("password", {
                required: true,
              })}
            />
          </label>
          <Link
            to="/reset-password"
            className="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"
          >
            Forgot password?
          </Link>
          {errorsForm.password?.type === "required" && (
            <p className="mt-3 text-gray-500 dark:text-gray-300">Required</p>
          )}
        </div>

        <div className="mt-6">
          {isSubmitting && (
            <div>
              <Spinner />
            </div>
          )}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
          >
            Sign in
          </button>
        </div>
      </form>

      <p className="mt-6 text-sm text-center text-gray-400">
        Don&apos;t have an account yet?
        <Link
          to="/signup"
          className="text-blue-500 focus:outline-none focus:underline hover:underline"
        >
          Sign up
        </Link>
        .
      </p>
    </div>
  );
}

export default JWTLoginForm;
