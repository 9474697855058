/* eslint-disable react/jsx-no-bind */
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useOuterClick } from "../../utils/key-press-utils";

function ProfileButton() {
  const [opened, setOpened] = useState(false);
  const clickOutside = useOuterClick(() => setOpened(false));
  function closeDropdownUser() {
    setOpened(false);
  }

  return (
    <div ref={clickOutside} className="relative">
      <div className="inline-flex shadow-none rounded-sm divide-x divide-gray-300">
        <button
          onClick={() => setOpened(!opened)}
          className="bg-gray-50 border border-gray-100 focus:outline-none focus:ring-2 focus:ring-theme-100 focus:ring-offset-2 focus:ring-offset-theme-50 focus:text-theme-900 focus:z-10 font-medium hover:text-theme-800 inline-flex items-center relative rounded-full shadow-inner text-slate-400"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
          type="button"
        >
          <span className="inline-block h-5 w-5 rounded-full overflow-hidden">
            <svg
              className="h-full w-full"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        </button>
      </div>

      <Transition
        as={Fragment}
        show={opened}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          v-show="dropDownUser"
          className="z-40 origin-top-right absolute right-0 mt-2 w-64 rounded-sm shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div
            className="py-1 rounded-sm bg-white shadow-xs"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            {/* <a
              className="truncate group flex items-center px-4 py-2 text-sm text-gray-700 transition ease-in-out duration-150"
              role="menuitem"
            >
              <div className="flex flex-col space-y-1 truncate">
                <div className="font-medium">Profile name</div>
                <div className="font-bold truncate">Email</div>
              </div>
            </a> */}

            <p className="truncate group flex items-center px-4 py-2 text-sm text-gray-700 transition ease-in-out duration-150">
              Profile name
            </p>
            <p className="truncate group flex items-center px-4 py-2 text-sm text-gray-700 transition ease-in-out duration-150">
              Email
            </p>
            <div className="border-t border-gray-200" />

            <Link
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
              role="menuitem"
              onClick={closeDropdownUser}
              to="/app/settings/profile"
            >
              <p>Profile</p>
            </Link>
            <Link
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
              role="menuitem"
              onClick={closeDropdownUser}
              to="/app/settings/workspaces"
            >
              <p>Workspaces</p>
            </Link>

            <Link
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
              role="menuitem"
              onClick={closeDropdownUser}
              to="/app/settings/subscription"
            >
              <p>Subscription</p>
            </Link>

            <div className="border-t border-gray-200 mt-1" />

            <button
              //   onClick={signOut}
              className="text-left w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 focus:outline-none"
              role="menuitem"
              type="button"
            >
              <p>Sign out</p>
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default ProfileButton;
