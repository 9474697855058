import React from "react";
import MainLayout from "../../components/main-layout";

import { useUserSubscription } from "../../hooks/use-user-subscription";

function Success() {
  const hasActiveSubscription = useUserSubscription();

  return (
    <MainLayout>
      <section>
        <div className="container px-6 py-16 mx-auto text-center">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white md:text-4xl">
              Success
            </h1>
            <p className="mt-6 text-gray-500 dark:text-gray-300">
              Payment was successful.
            </p>
            {hasActiveSubscription && (
              <p className="mt-6 text-gray-500 dark:text-gray-300">
                You have an active subscription
              </p>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export default Success;
