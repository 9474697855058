import { useAppSelector } from "../app/hooks";
import {
  selectAccess,
  selectUser,
  selectStatus,
  selectIsAuthenticated,
  selectError,
} from "../features/authentication/auth-slice";

export const useAuth = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const error = useAppSelector(selectError);
  const user = useAppSelector(selectUser);
  const status = useAppSelector(selectStatus);
  const accessToken = useAppSelector(selectAccess);

  return {
    user,
    accessToken,
    isAuthenticated,
    status,
    error,
  };
};
