import React from "react";

export interface IErrorProps {
  error: {
    username?: string;
    password?: string;
    password_mismatch?: string;
    cannot_create_user?: string;
    invalid_credentials?: string;
    inactive_account?: string;
    email_not_found?: string;
    invalid_token?: string;
    invalid_uid?: string;
    stale_token?: string;
    invalid_password?: string;
    username_mismatch?: string;
    non_field_errors?: string;
    non_field_props?: string;
    detail?: string;
    uid?: string;
    token?: string;
    message?: string;
    bad_authorization_header?: string;
  };
}

function Error({ error }: IErrorProps) {
  return (
    <>
      {error && error.username && <p>{error.username}</p>}
      {error && error.password && <p>{error.password}</p>}
      {error && error.password_mismatch && <p>{error.password_mismatch}</p>}
      {error && error.cannot_create_user && <p>{error.cannot_create_user}</p>}
      {error && error.invalid_credentials && <p>{error.invalid_credentials}</p>}
      {error && error.inactive_account && <p>{error.inactive_account}</p>}
      {error && error.email_not_found && <p>{error.email_not_found}</p>}
      {error && error.invalid_token && <p>{error.invalid_token}</p>}
      {error && error.invalid_uid && <p>{error.invalid_uid}</p>}
      {error && error.stale_token && <p>{error.stale_token}</p>}
      {error && error.invalid_password && <p>{error.invalid_password}</p>}
      {error && error.username_mismatch && <p>{error.username_mismatch}</p>}
      {error && error.non_field_props && <p>{error.non_field_errors}</p>}
      {error && error.detail && <p>{error.detail}</p>}
      {error && error.uid && <p>{error.uid}</p>}
      {error && error.token && <p>{error.token}</p>}
      {error && error.message && <p>{error.message}</p>}
      {error && error.bad_authorization_header && (
        <p>{error.bad_authorization_header}</p>
      )}
    </>
  );
}

export default Error;
