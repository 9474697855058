import React from "react";

import { AppConfig } from "../../utils/app-config";
import MainLayout from "../../components/main-layout";
import ResetUsernameForm from "../../components/authentication/reset-username-form";

function ResetUserName() {
  return (
    <MainLayout>
      <div className="bg-white dark:bg-gray-900">
        <div className="flex justify-center h-screen">
          <div
            className="hidden bg-cover lg:block lg:w-2/3"
            style={{
              backgroundImage: "url(/static/images/undraw_launch_day_4e04.png)",
            }}
          >
            <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
              <div>
                <h2 className="text-4xl font-bold text-white">
                  {AppConfig.siteName}
                </h2>
                <p className="max-w-xl mt-3 text-gray-300">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. In
                  autem ipsa, nulla laboriosam dolores, repellendus perferendis
                  libero suscipit nam temporibus molestiae
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
            <div className="flex-1">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">
                  {AppConfig.siteName}
                </h2>
                <p className="mt-3 text-gray-500 dark:text-gray-300">
                  Enter your email address to reset your user name
                </p>
              </div>
              <ResetUsernameForm />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default ResetUserName;
