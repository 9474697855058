import React from "react";
import { Link } from "react-router-dom";

import { useUserSubscription } from "../../hooks/use-user-subscription";
import { getApiURL } from "../../utils/helpers";
import { useAuth } from "../../hooks/use-auth";
import Spinner from "../../components/ui/spinner/spinner";

function UserSubscriptionDetails() {
  const { accessToken } = useAuth();
  const { hasActiveSubscription, error, loading } = useUserSubscription();

  const subscriptionUpdateHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    const response = await fetch(
      `${getApiURL()}/api/subscriptions/customer-portal/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${accessToken}`,
          Accept: "application/json",
        },
      },
    );
    const data = await response.json();
    window.location.assign(data.url);
  };

  return (
    <div className="flex-1 relative pb-8 z-0 overflow-y-auto">
      <div className="hidden md:block bg-white shadow lg:border-t lg:border-gray-200">
        <div className="px-4 sm:px-8  mx-auto">
          <div className="flex-1 min-w-0">
            <h1>Subscription Details</h1>
          </div>
        </div>
      </div>

      <div className="px-4 sm:px-8 mx-auto py-5 grid gap-5">
        <div className="mt-2 grid sm:grid-cols-1 gap-5">
          <div className="space-y-5">
            <div className="flex items-center space-x-2 justify-between">
              {error && <p>An error happened. please try again.</p>}
              {loading && <Spinner />}
              {!loading && (
                <div className="w-full max-w-sm px-4 py-3 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
                  <div>
                    <h1 className="mt-2 text-lg font-semibold text-gray-800 dark:text-white">
                      Your Subscription Status:
                    </h1>
                  </div>

                  {!hasActiveSubscription && (
                    <div>
                      <div className="flex items-center mt-2 text-gray-700 dark:text-gray-200">
                        <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                          Yo do not have an active subscription.
                        </p>
                      </div>
                      <div className="flex items-center justify-center mt-4">
                        <Link to="/pricing">
                          <button
                            type="submit"
                            className="px-4 py-2 font-medium tracking-wide text-white  transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                          >
                            Subscribe to a plan
                          </button>
                        </Link>
                      </div>
                    </div>
                  )}

                  {hasActiveSubscription && (
                    <div>
                      <div className="flex items-center mt-2 text-gray-700 dark:text-gray-200">
                        <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                          You have an active subscription
                        </p>
                      </div>
                      <div className="flex items-center justify-center mt-4">
                        <form onSubmit={subscriptionUpdateHandler}>
                          <button
                            type="submit"
                            onClick={subscriptionUpdateHandler}
                            className="px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                          >
                            Update your subscription
                          </button>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSubscriptionDetails;
