import React from "react";

function Dashboard() {
  return (
    <div className="flex-1 relative pb-8 z-0 overflow-y-auto">
      <div className="hidden md:block bg-white shadow lg:border-t lg:border-gray-200">
        <div className="px-4 sm:px-8  mx-auto">
          <div className="flex-1 min-w-0">
            <h1>Dashboard Home</h1>
          </div>
        </div>
      </div>

      <div className="px-4 sm:px-8 mx-auto py-5 grid gap-5">
        <div className="mt-2 grid sm:grid-cols-1 gap-5">
          <div className="space-y-5">
            <div className="flex items-center space-x-2 justify-between">
              <h3 className="leading-5 text-gray-900">Summary</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
