import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserDetails } from "../../types";

import {
  logoutAsync,
  signupAsync,
  AuthApiError,
  loginAsync,
  loadUserAsync,
  userActivateAsync,
  userReActivateAsync,
  userDeleteAsync,
  resetUserNameAsync,
  confirmUsernameReset,
  resetPasswordAsync,
  resetPasswordConfirmAsync,
  refreshAccessTokenAsync,
} from "./auth-api";

export interface AuthState {
  access: string | null;
  isAuthenticated: boolean;
  user: UserDetails | null;
  error: AuthApiError | null;
  status: "idle" | "loading" | "failed";
}

const initialState: AuthState = {
  access: null,
  isAuthenticated: false,
  user: null,
  error: null,
  status: "idle",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutAsync.pending, () => initialState)
      .addCase(logoutAsync.rejected, (state, action) => {
        state.status = "failed";
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
        state.error = action.payload as AuthApiError;
      })
      .addCase(logoutAsync.fulfilled, () => initialState)
      .addCase(signupAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signupAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(signupAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as AuthApiError;
      })
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.access) {
          state.isAuthenticated = true;
          state.access = action.payload.access;
          state.error = null;
        } else {
          state.isAuthenticated = false;
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as AuthApiError;
      })
      .addCase(loadUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(loadUserAsync.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
      })
      .addCase(userActivateAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userActivateAsync.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(userActivateAsync.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
      })
      .addCase(userReActivateAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userReActivateAsync.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(userReActivateAsync.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
      })
      .addCase(userDeleteAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userDeleteAsync.fulfilled, (state) => {
        state.status = "idle";
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(userDeleteAsync.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
      })
      .addCase(resetUserNameAsync.pending, (state) => {
        state.status = "loading";
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(resetUserNameAsync.fulfilled, (state) => {
        state.status = "idle";
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(resetUserNameAsync.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(confirmUsernameReset.pending, (state) => {
        state.status = "loading";
      })
      .addCase(confirmUsernameReset.fulfilled, (state) => {
        state.status = "idle";
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(confirmUsernameReset.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.status = "idle";
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(resetPasswordConfirmAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPasswordConfirmAsync.fulfilled, (state) => {
        state.status = "idle";
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(resetPasswordConfirmAsync.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      })
      .addCase(refreshAccessTokenAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(refreshAccessTokenAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.access = action.payload;
          state.isAuthenticated = true;
        } else {
          state.isAuthenticated = false;
        }
      })
      .addCase(refreshAccessTokenAsync.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload as AuthApiError;
        } else {
          state.error = action.error as AuthApiError;
        }
        state.isAuthenticated = false;
        state.access = null;
        state.user = null;
      });
  },
});

// export const { logout} = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

export const selectAccess = (state: RootState) => state.auth.access;

export const selectUser = (state: RootState) => state.auth.user;

export const selectError = (state: RootState) => state.auth.error?.errorMessage;

export const selectStatus = (state: RootState) => state.auth.status;

export default authSlice.reducer;
