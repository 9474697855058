import { useState, useEffect } from "react";

import { getApiURL } from "../utils/helpers";
import { useAuth } from "./use-auth";

export const useUserSubscription = () => {
  const { accessToken } = useAuth();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();

    const getUserSubscriptionDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${getApiURL()}/api/subscriptions/my-subscription/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${accessToken}`,
              Accept: "application/json",
            },
            signal: abortController.signal,
          },
        );
        const data = await response.json();

        if (response.status === 200 && data.length > 0) {
          setHasActiveSubscription(true);
        }
        // if (response.status === 400) {
        //   console.log(data.error);
        //   setError(data.error);
        // }
      } catch (err) {
        setError("An error happened");
        // if (!abortController.signal.aborted) {
        //   console.log('abort');
        // }
      }
      setLoading(false);
    };

    getUserSubscriptionDetails();

    return () => {
      abortController.abort();
      setLoading(false);
    };
  }, [accessToken]);

  return { hasActiveSubscription, error, loading };
};
