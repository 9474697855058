import React from "react";
import AccountDeleteForm from "../../components/account/account-delete-form";

function UserDelete() {
  return (
    <div className="flex-1 relative pb-8 z-0 overflow-y-auto">
      <div className="hidden md:block bg-white shadow lg:border-t lg:border-gray-200">
        <div className="px-4 sm:px-8  mx-auto">
          <div className="flex-1 min-w-0">
            <h1 className="">Account Delete</h1>
          </div>
        </div>
      </div>

      <div className="px-4 sm:px-8 mx-auto py-5 grid gap-5">
        <div className="mt-2 grid sm:grid-cols-1 gap-5">
          <div className="space-y-5">
            <div className="flex items-center space-x-2 justify-between">
              <div className="w-full max-w-sm px-4 py-3 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
                <div>
                  <h1 className="mt-2 text-lg font-semibold text-gray-800 dark:text-white">
                    Are you sure?
                  </h1>
                </div>
                <div>
                  <div className="flex items-center mt-2 text-gray-700 dark:text-gray-200">
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                      You can contact us if you have any questions.
                    </p>
                  </div>
                  <div className="flex items-center justify-center mt-4">
                    <AccountDeleteForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDelete;
